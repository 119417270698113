import { Typography } from "@mui/material";
import "./Flippable.css";
import { useState, useEffect } from "react";

const Flippable = ({ text }) => {
  const [content, setContent] = useState(1);
  const [flip, setFlip] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFlip(true);
      setTimeout(() => {
        setContent((prevContent) => (prevContent + 1) % text.length);
      }, 500); // Change content in the middle of the animation

      setTimeout(() => {
        setFlip(false);
      }, 1000); // Reset flip class after the animation completes
    }, 3000);

    return () => clearInterval(intervalId);
  }, [text]);

  return (
    <div className={`Greet ${flip ? "flip" : ""}`}>
      <Typography
        variant="h2"
        component="div"
        sx={{
          fontSize: {
            xs: "1.3rem", // Extra small devices
            sm: "1.75rem", // Small devices
            md: "1.75rem", // Medium devices
            lg: "1.75rem", // Large devices
          },
        }}>
        {text[content]}!
      </Typography>
    </div>
  );
};

export default Flippable;
