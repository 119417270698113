import React from "react";
import NavBar from "./Components/NavBar";
import About from "./Pages/About/About";
import LandingPage from "./Pages/LandingPage/LandingPage";
import ProjectDetailed from "./Pages/ProjectDetailed/ProjectDetailed";
import { Routes, Route } from "react-router-dom";
import Gallery from "./Pages/Gallery/Gallery";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/projects/:projectName" element={<ProjectDetailed />} />
      </Routes>
    </div>
  );
}

export default App;
