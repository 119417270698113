import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const ProjectCard = ({ project }) => {
  const nav = useNavigate();
  const navigate = (page) => {
    nav(page);
  };

  return (
    <div
      style={{
        width: { xs: "90%", sm: "95%", md: "100%" }, // Ensure responsive width
        margin: "0 auto", // Ensure it stays centered
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5%",
      }}>
      <Card
        sx={{
          boxShadow: "none",
          display: "flex", // Ensures the content inside the card is flexible
          justifyContent: "center", // Centers horizontally
          alignItems: "center", // Centers vertically
          flexDirection: "column", // Align items in column direction
        }}>
        <CardMedia
          sx={{
            height: "30vh", // Ensure the card media has a fixed height
            width: { xs: "100%", sm: "100%" }, // Responsive width for different screen sizes
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}>
          <Box
            sx={{
              width: "95%", // Set a reasonable width for the box
              height: "100%", // Make the box take up full height of the CardMedia
              backgroundColor: "#f1ebf8",
              display: "flex", // Flexbox for centering the image inside
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              padding: "10px",
              borderRadius: "20px",
            }}>
            <img
              src={project.image}
              alt={project.name}
              style={{
                maxWidth: "85%", // Image takes up 85% of the box's width
                maxHeight: "85%", // Image takes up 85% of the box's height
                objectFit: "contain", // Ensures the image fits within the container without cropping
                borderRadius: "10px", // Adds border-radius for styling
              }}
            />
          </Box>
        </CardMedia>
        <CardContent>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "Bold",
              padding: 0,
              fontSize: {
                xs: "1.25rem", // Extra small devices
                sm: "1.8rem", // Small devices
                md: "2rem", // Medium devices
                lg: "2rem", // Large devices
              },
              transition: "color 0.3s ease",
            }}>
            {project.name}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              marginTop: "3%",
              color: "#646F77",
              fontSize: {
                xs: "1rem", // Extra small devices
                sm: "1.15rem", // Small devices
                md: "1.15rem", // Medium devices
                lg: "1.15rem", // Large devices
              },
            }}>
            {project.description}
          </Typography>
          {project.tags.map((item) => (
            <Button
              key={item}
              variant="outlined"
              disabled
              sx={{
                "&.Mui-disabled": {
                  backgroundColor: "white",
                  color: "#646F77",
                  borderColor: "#646f77ac",
                },
                textTransform: "none",
                borderRadius: "10px",
                marginTop: "5%",
                marginRight: "2%",
                fontSize: {
                  xs: "0.55em", // Extra small devices
                  sm: "0.7em", // Small devices
                  md: "0.7em", // Medium devices
                  lg: "0.7em", // Large devices
                },
              }}>
              {item}
            </Button>
          ))}
        </CardContent>
        <CardActions
          sx={{
            width: "100%", // Ensure the CardActions takes the full width of the card
            justifyContent: "flex-start", // Aligns the button to the left
            paddingLeft: "16px", // Optional: Add some padding to the left for spacing
            marginTop: "-16px",
          }}>
          <Button
            size="small"
            onClick={() => navigate("/projects/" + project.name)}
            sx={{
              padding: 0,
              textTransform: "none", // Prevents all caps
              textDecoration: "underline", // Adds underline
              color: "#555d63",
              fontSize: {
                xs: "0.8em", // Extra small devices
                sm: "0.9em", // Small devices
                md: "0.95em", // Medium devices
                lg: "1em", // Large devices
              },
            }}>
            {`More Details  `}
            <ArrowOutwardIcon
              sx={{
                textDecoration: "underline",
                fontSize: {
                  xs: "1em", // Extra small devices
                  sm: "0.9em", // Small devices
                  md: "0.95em", // Medium devices
                  lg: "1em", // Large devices
                },
                marginLeft: "3px",
                transition: "color 0.3s ease",
              }}
            />
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default ProjectCard;
