export const FlowWeave = [
  {
    title: "Overview",
    content:
      "FlowWeave is a visual scripting application for JavaScript. It allows users to build Code using a node-based interface, reducing the need to write code manually. The project leverages technologies like the  React Flow, and WebContainer for dynamic code execution within the browser.",
  },
  {
    title: "Goal",
    content:
      "The goal of FlowWeave is to simplify Javascript development by providing a visual interface that allows developers to create Flows and generate code without needing to manually write every line. Heavily inspired by Unreal Blueprints  FlowWeave aims to bridge the gap between coding and visual scripting.",
  },
  {
    title: "Stack",
    content: "MERN",
  },
  {
    title: "Tools",
    content: "WebContainer, ReactFlow",
  },
  {
    title: "Duration",
    content: "1 Month",
  },
  {
    title: "Url",
    content: null,
  },

  {
    title: "Features",
    items: [
      {
        feature: "Visual Scripting Interface",
        description:
          "A node-based interface where users can connect JavaScript functions and backend modules visually.",
      },
      {
        feature: "Dynamic Folder & File Management",
        description:
          "Users can manage files and folders dynamically with an explorer interface similar to VSCode.",
      },
      {
        feature: "NPM Package Integration",
        description:
          "Users can search and add npm packages, and see their functions as draggable nodes.",
      },
      {
        feature: "Code Generation and Execution",
        description:
          "Nodes and edges in the flow are converted into executable JavaScript code and run inside a WebContainer.",
      },
      {
        feature: "Variable Management (Getters/Setters)",
        description:
          "Users can declare variables and manage state with getter and setter nodes.",
      },
      {
        feature: "Node Types and Custom Functions",
        description:
          "Supports built-in JavaScript functions, custom variables, and npm package functions as nodes.",
      },
    ],
  },

  {
    title: "Challenges & Obstacles",
    items: [
      {
        challenge: "Node-to-Code Translation",
        description:
          "Converting node-based flows into Db entries and  executable JavaScript was complex, especially when handling variables and function chaining.",
      },
      {
        challenge: "WebContainer Integration",
        description:
          "Integrating WebContainers to run Javascript code within the browser in real-time was a technical challenge that required extensive experimentation and stepping out of default React settings as WebContainers have heavy restrictions and had to go with craco(Create React App Configuration Override) to have webcontainer running.",
      },
    ],
  },
  {
    title: "Learning Outcomes",
    items: [
      {
        learning: "Advanced React Flow Knowledge",
        description:
          "Deepened understanding of React Flow and how to adapt it for visual scripting and node-based interfaces.",
      },
      {
        learning: "Full-Stack Development Insights",
        description:
          "Gained experience in integrating frontend and backend communication in complex applications, handling APIs, and managing persistent data.",
      },
      {
        learning: "Working with WebContainers",
        description:
          "Learned how to run real-time Node.js code execution within the browser using WebContainers, including the challenges of sandboxing and security.",
      },
    ],
  },
  {
    title: "Future Improvements",
    items: [
      {
        improvement: "Scalability Enhancements",
        description:
          "Plans to improve handling of larger, more complex flows with thousands of nodes and connections.",
      },
      {
        improvement: "User Authentication and Collaboration",
        description:
          "Potential addition of user authentication and real-time collaboration for multiple users to work on the same project.",
      },
      {
        improvement: "Better Debugging & Error Handling",
        description:
          "Plans to integrate more robust debugging tools and better error handling when flows encounter execution issues.",
      },
    ],
  },
  {
    title: "Conclusion",
    content:
      "Developing FlowWeave was an enriching experience that deepened my understanding of MERN. This project wouldn't have been possible without React Flow and WebContainers. Moving forward, I am working on adding NPM support and exploring the possibility of spinning up an Express server with FlowWeave.",
  },
];

export const DataLens = [
  {
    title: "Overview",
    content:
      "DataLens is a real-time data dashboard project that allows users to interact using natural language. It generates custom visualizations and reports tailored for teams in sales, finance, or marketing. The application leverages React, Java Spring Boot, and NLP AI for seamless user interactions and data visualization.",
  },
  {
    title: "Goal",
    content:
      "The goal of DataLens is to simplify data exploration and reporting by allowing users to query data using natural language and instantly generate insightful visualizations. It bridges the gap between technical data analysis and user-friendly interfaces for non-technical users.",
  },
  {
    title: "Stack",
    content: "React, Java Spring Boot",
  },
  {
    title: "Tools",
    content: "React, Hugging Face",
  },
  {
    title: "Duration",
    content: "Ongoing",
  },
  {
    title: "Url",
    content: null,
  },
  {
    title: "Features",
    items: [
      {
        feature: "Natural Language Processing (NLP) Queries",
        description:
          "Users can interact with the dashboard using natural language queries to generate custom reports and visualizations.",
      },
      {
        feature: "Real-Time Data Updates",
        description:
          "DataLens provides live data updates in visualizations, enabling users to monitor metrics in real time.",
      },
      {
        feature: "Custom Data Visualizations",
        description:
          "Generates a variety of charts and reports tailored to user queries, using a free alternative to Highcharts.",
      },
      {
        feature: "Cross-Team Collaboration",
        description:
          "Designed for sales, finance, and marketing teams to explore data and collaborate on reports with ease.",
      },
    ],
  },
  {
    title: "Challenges & Obstacles",
    items: [
      {
        challenge: "Natural Language Understanding",
        description:
          "Implementing accurate and reliable NLP to process a wide variety of user queries was challenging and required extensive fine-tuning.",
      },
      {
        challenge: "Real-Time Data Handling",
        description:
          "Handling live data streams and ensuring real-time updates to visualizations without performance degradation required optimization efforts.",
      },
    ],
  },
  {
    title: "Learning Outcomes",
    items: [
      {
        learning: "NLP Integration",
        description:
          "Improved understanding of NLP techniques and how to integrate them with complex data systems to provide user-friendly interactions.",
      },
      {
        learning: "Real-Time Data Management",
        description:
          "Gained experience in managing real-time data pipelines and ensuring efficient delivery of updates to the frontend.",
      },
      {
        learning: "Data Visualization Strategies",
        description:
          "Explored different free and open-source tools for generating customizable and interactive data visualizations.",
      },
    ],
  },
  {
    title: "Future Improvements",
    items: [
      {
        improvement: "Enhanced Query Accuracy",
        description:
          "Plans to improve the NLP model to handle even more complex queries and deliver more accurate data insights.",
      },
      {
        improvement: "Expanded Visualization Options",
        description:
          "Exploring new types of visualizations and improving the customization options available to users.",
      },
      {
        improvement: "User Roles and Permissions",
        description:
          "Introducing user roles and permissions for more secure and customizable team-based data access.",
      },
    ],
  },
  {
    title: "Conclusion",
    content:
      "DataLens has been an exciting project that explores the intersection of natural language processing and data visualization. By simplifying data interaction, it empowers non-technical users to access and analyze critical data. Moving forward, there are plans to enhance its NLP capabilities and expand its visualization toolkit.",
  },
];
