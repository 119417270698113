import {
  ReactFlow,
  Background,
  useNodesState,
  useEdgesState,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { generateNodesAndEdges } from "./FlowNodesEdges"; // Import your function
import CustomNode from "./CustomNode";
import { useState, useEffect } from "react"; // Import useEffect
import { useRef } from "react";
// Skill structure definition
const skillStructure = {
  skills: {
    label: "Skills",
    children: [
      {
        id: "webdev",
        label: "Web Development",
        children: [
          {
            id: "frontend",
            label: "Frontend",
            children: ["React", "HTML", "CSS", "Redux"],
          },
          {
            id: "backend",
            label: "Backend",
            children: ["Node.js", "Express", "Spring"],
          },
          { id: "database", label: "Database", children: ["MySQL", "MongoDB"] },
        ],
      },
      {
        id: "infra",
        label: "DevOps / Infrastructure",
        children: [
          { id: "cloud", label: "Cloud Platforms", children: ["AWS", "Azure"] },
          { id: "os", label: "Operating Systems", children: ["Linux"] },
        ],
      },
      {
        id: "others",
        label: "Others",
        children: ["IBM FileNet", "Blender", "Unreal Engine"],
      },
    ],
  },
};

const nodeTypes = {
  customNode: CustomNode,
};

const SkillsComponent = () => {
  const isMounted = useRef(false); // Track whether the effect has already run

  const [selectedNode, setSelectedNode] = useState(null); // Define selectedNode state
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    if (!isMounted.current) {
      // Check if it's the first render
      const { nodes: initialNodes, edges: initialEdges } =
        generateNodesAndEdges(skillStructure, selectedNode, setSelectedNode);
      setNodes(initialNodes);
      setEdges(initialEdges);
      isMounted.current = true; // Set ref to true after the first run
    }
  }, [selectedNode, setNodes, setEdges]);
  const isSmallDevice = window.innerWidth <= 768; // Define small device breakpoint (e.g., 768px)
  const initialViewport = {
    x: -75,
    y: isSmallDevice ? 0 : -80,
    zoom: isSmallDevice ? 0.5 : 0.7, // 0.5 for small devices, 0.75 for larger devices
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        defaultViewport={initialViewport}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}>
        <Background color="#3a3939" gap={18} />
      </ReactFlow>
    </div>
  );
};
export default SkillsComponent;
