import { Box, Typography } from "@mui/material";

import HeroSection from "../../Components/Herosection/HeroSection";
import "./LandingPage.css";
import ProjectLister from "../../Components/ProjectLister/ProjectLister";
import SkillsComponent from "../../Components/Skills/SkillsComponent";
import Footer from "../../Components/Footer/Footer";

const LandingPage = () => {
  return (
    <div>
      <HeroSection />
      <div style={{ marginTop: "15%" }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            color: "#646F77",
            marginLeft: "6%",
            fontSize: {
              xs: "1.4rem", // Extra small devices
              sm: "2rem", // Small devices
              md: "2rem", // Medium devices
              lg: "2rem", // Large devices
            },
          }}>
          Featured Projects
        </Typography>
        <div className="ProjectList" style={{ marginTop: "5%" }}>
          <ProjectLister />
        </div>
      </div>
      <Typography
        variant="h3"
        sx={{
          fontWeight: "bold",
          color: "#646F77",
          marginLeft: "6%",
          marginTop: "6%",
          fontSize: {
            xs: "1.4rem", // Extra small devices
            sm: "2rem", // Small devices
            md: "2rem", // Medium devices
            lg: "2rem", // Large devices
          },
        }}>
        Skills
      </Typography>
      <div>
        <Box
          sx={{
            height: "65vh", // Fixed height
            width: {
              xs: "95vw", // Width for small devices (phones)
              lg: "40vw", // Width for large devices (desktops)
            },
            margin: "0 auto", // Centering the component horizontally
            marginTop: "3%",
          }}>
          <SkillsComponent />
        </Box>
      </div>
      <div
        style={{
          background: `url("/Mask group.png")`,
          width: "100%",
          height: "50rem",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position: "relative", // Ensure positioning context
          display: "flex", // Use flexbox to manage layout
          flexDirection: "column", // Stack elements vertically
          justifyContent: "flex-end", // Align children to the end
        }}>
        <Footer />
      </div>
    </div>
  );
};
export default LandingPage;
