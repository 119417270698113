import reactImg from "../../assets/Logos/react.svg";
import htmlImg from "../../assets/Logos/html.svg";
import cssImg from "../../assets/Logos/css.svg";
import reduxImg from "../../assets/Logos/redux.svg";
import nodeImg from "../../assets/Logos/nodejs.svg";
import expressImg from "../../assets/Logos/express.svg";
import springIcon from "../../assets/Logos/spring.svg";
import backend from "../../assets/Logos/backend.svg";
import webDev from "../../assets/Logos/webdev.svg";
import frontend from "../../assets/Logos/frontend.svg";
import mysqlImg from "../../assets/Logos/mysql.svg";
import mongodbImg from "../../assets/Logos/mongodb.svg";
import awsImg from "../../assets/Logos/aws.svg";
import azureImg from "../../assets/Logos/azure.svg";
import linuxImg from "../../assets/Logos/linux.svg";
import ibmImg from "../../assets/Logos/ibm.svg";
import blenderImg from "../../assets/Logos/blender.svg";
import unrealImg from "../../assets/Logos/unreal.svg";
import database from "../../assets/Logos/database.svg";
import Cloud from "../../assets/Logos/cloud.svg";
import infra from "../../assets/Logos/infra.svg";
import os from "../../assets/Logos/Os.svg";
import other from "../../assets/Logos/others.svg";

// Skill structure definition

let edgeCounter = 0;

// Function to generate nodes and edges from the skill structure
export const generateNodesAndEdges = (
  structure,
  selectedNode,
  setSelectedNode
) => {
  const nodes = [];
  const edges = [];

  const imageMap = {
    React: reactImg,
    HTML: htmlImg,
    CSS: cssImg,
    Redux: reduxImg,
    "Node.js": nodeImg,
    Express: expressImg,
    Spring: springIcon,
    Backend: backend,
    "Web Development": webDev,

    MySQL: mysqlImg,
    MongoDB: mongodbImg,
    AWS: awsImg,
    Azure: azureImg,
    Linux: linuxImg,
    "IBM FileNet": ibmImg,
    Blender: blenderImg,
    "Unreal Engine": unrealImg,
    Database: database,
    Frontend: frontend,
    "Operating Systems": os,
    "DevOps / Infrastructure": infra,
    "Cloud Platforms": Cloud,
    Others: other,
  };

  // Recursive function to traverse the structure and position nodes
  const traverse = (
    parentId,
    children,
    parentAngle,
    parentRadius,
    isRoot = false
  ) => {
    if (!children) return; // Exit if no children

    const childCount = children.length;

    // Adjust angle spread based on whether it's a root or a direct parent
    const angleSpread = isRoot ? 360 : parentId === "webdev" ? 180 : 60;
    const angleStep = angleSpread / childCount;

    children.forEach((child, index) => {
      const angle = parentAngle - angleSpread / 2 + index * angleStep; // Spread parent nodes

      const {
        id,
        label,
        children: grandChildren,
      } = typeof child === "string" ? { id: child, label: child } : child;

      // Create child node
      const childNodeId = id || `${parentId}-${index}`;
      nodes.push({
        id: childNodeId,
        position: getCircularPosition(parentRadius, angle),
        data: {
          label: (
            <img
              src={imageMap[label]}
              alt={label}
              style={{
                width: "50px",
                height: "50px",
                imageRendering: "crisp-edges",
              }}
            />
          ), // Use local image
        },
        // style: {
        //   opacity: selectedNode === childNodeId ? 1 : 0.5,
        //   transition: "all 0.3s ease",
        // },
        type: "customNode",
      });

      // Create edge between parent and child
      edges.push({
        id: `e-${parentId}-${childNodeId}-${edgeCounter++}`, // Add index to ensure uniqueness
        source: parentId,
        target: childNodeId,
        className: "pulse-edge",
      });

      // Recursively traverse grandChildren with smaller radius increment for child nodes
      if (grandChildren) {
        traverse(childNodeId, grandChildren, angle, parentRadius + 100); // Closer radius for grandChildren
      }
    });
  };

  // Start traversing from the main skills node's children
  traverse("skills", structure.skills.children, 0, 150, true); // Larger starting radius for parent categories

  // Add the root node (Skills)
  nodes.push({
    id: "skills",
    position: { x: 500, y: 500 }, // Center position
    data: { label: "Skills" },
    type: "customNode",
  });

  // Start traversing from the main skills node's children
  traverse("skills", structure.skills.children, 0, 150, true); // Larger starting radius for parent categories

  return { nodes, edges };
};

// Function to calculate circular positions
const getCircularPosition = (
  radius,
  angleInDegrees,
  centerX = 500,
  centerY = 500
) => {
  const angleInRadians = (angleInDegrees * Math.PI) / 180;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

// Generate the nodes and edges
// const { nodes: initialNodes, edges: initialEdges } =
//   generateNodesAndEdges(skillStructure);

// export { initialEdges, initialNodes };
