import { useParams } from "react-router-dom";
import { FlowWeave, DataLens } from "../../Data/ProjectDetailed";
import { Box, Grid, Typography } from "@mui/material";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

const ProjectDetailed = () => {
  const { projectName } = useParams();
  const currentProject = projectName === "FlowWeave" ? FlowWeave : DataLens;
  const titleImage =
    projectName === "FlowWeave" ? "/Assets/FlowWeave/titleImage.gif" : null;
  // Initialize variables to store different sections of the project details
  let overview = "";
  let goal = "";
  let stack = "";
  let tools = "";
  let duration = "";
  let url = "";

  let challenges = [];
  let conclusion = "";

  // Iterate over the FlowWeave array and assign values based on title
  currentProject.forEach((section) => {
    switch (section.title) {
      case "Overview":
        overview = section.content;
        break;
      case "Goal":
        goal = section.content;
        break;
      case "Stack":
        stack = section.content;
        break;
      case "Tools":
        tools = section.content;
        break;
      case "Duration":
        duration = section.content;
        break;
      case "Url":
        url = section.content;
        break;

      case "Challenges & Obstacles":
        challenges = section.items;
        break;

      case "Conclusion":
        conclusion = section.content;
        break;
      default:
        break;
    }
  });
  const codeString = `let a = 0;
let b = 1;
let next = 0;
const limit = 100;
console.log(a);
while(b<=limit){
    console.log(b);
next = a+b;
a = b;
b = next;

  }`;
  const properties = {
    Stack: stack,
    Tools: tools,
    Duration: duration,
    Url: url,
  };
  const isSmallDevice = window.innerWidth <= 768; // Define small device breakpoint (e.g., 768px)

  return (
    <div
      style={{
        marginTop: "6%",
        background: "none", // This removes any background image
        backgroundColor: "#ffffff", // Optionally, set a solid background color
      }}>
      <div style={{ marginTop: "6%" }}>
        <div
          className="projectTitle"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}>
          <Typography
            variant="h4"
            sx={{
              color: "#646F77",
              fontWeight: "bold",
              fontSize: {
                xs: "1.4rem", // Extra small devices
                sm: "2rem", // Small devices
                md: "2rem", // Medium devices
                lg: "2rem", // Large devices
              },
              letterSpacing: "0.1em",
            }}>
            {projectName}
          </Typography>
          <Box
            component="img"
            src={titleImage}
            alt="Project Title"
            sx={{
              marginTop: "3%",
              borderRadius: "20px",
              width: {
                xs: "85%", // 85% width on small devices
                md: "40%", // 60% width on medium devices and larger
              },
            }}
          />
        </div>
      </div>
      <Box
        component="div"
        sx={{
          marginLeft: {
            xs: "3%",
            md: "20%",
          },
          marginTop: "6%",
        }}>
        <div className="jumpTo"></div>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 0 } }}>
            <Box
              component="div"
              sx={{
                marginTop: {
                  xs: "3%",
                  md: 0,
                },
              }}
              className="overview">
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  fontSize: {
                    xs: "1.2rem", // Extra small devices
                    sm: "1.7rem", // Small devices
                    md: "1.7rem", // Medium devices
                    lg: "1.7rem", // Large devices
                  },
                }}>
                Overview
              </Typography>
              <Box
                component="div"
                sx={{ width: { xs: "90%", md: "80%", marginLeft: "5%" } }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "inter",
                    fontSize: "1.2rem",
                    color: "#646F77",
                  }}>
                  {overview}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ order: { xs: 0, md: 1 } }}>
            {Object.keys(properties).map((key, value) => (
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  fontFamily: "inter",
                  marginTop: "2%",
                }}>
                <span>{key}: </span>
                <span
                  style={{
                    fontWeight: "normal",
                    fontFamily: "inter",
                    fontSize: "1.1rem",
                    color: "#646F77",
                  }}>
                  {properties[key]}
                </span>
              </Typography>
            ))}
          </Grid>
        </Grid>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            fontSize: {
              xs: "1.2rem", // Extra small devices
              sm: "1.7rem", // Small devices
              md: "1.7rem", // Medium devices
              lg: "1.7rem", // Large devices
            },
            marginTop: "3%",
          }}>
          Goal
        </Typography>
        <Box
          component="div"
          sx={{ width: { xs: "90%", md: "80%", marginLeft: "5%" } }}>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "inter",
              fontSize: "1.2rem",
              color: "#646F77",
            }}>
            {goal}
          </Typography>
        </Box>
        {projectName === "FlowWeave" ? (
          <div>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                fontSize: {
                  xs: "1.2rem", // Extra small devices
                  sm: "1.7rem", // Small devices
                  md: "1.7rem", // Medium devices
                  lg: "1.7rem", // Large devices
                },
                marginTop: "3%",
              }}>
              Demo
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "inter",
                fontSize: "1.2rem",
                color: "#646F77",
              }}>
              Below is how nodes are placed when constructing a flow
            </Typography>
            <div
              className="Demo"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}>
              <Box
                component="img"
                src="/Assets/FlowWeave/Demo.gif"
                alt="Project Title"
                sx={{
                  marginTop: "3%",
                  marginLeft: {
                    xs: 0,
                    md: "-10%",
                  },
                  borderRadius: "20px",
                  width: {
                    xs: "85%", // 85% width on small devices
                    md: "60%", // 60% width on medium devices and larger
                  },
                }}
              />
            </div>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "inter",
                fontSize: "1.2rem",
                color: "#646F77",
                marginTop: "3%",
              }}>
              And the completed Flow for a Fibonacci series and the generated
              code looks like:
            </Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                <div
                  className="Demo"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}>
                  <Box
                    component="img"
                    src="/Assets/FlowWeave/completeProgram.jpg"
                    alt="Project Title"
                    sx={{
                      marginTop: "3%",
                      marginLeft: {
                        xs: 0,
                        md: "-10%",
                      },
                      borderRadius: "20px",
                      width: {
                        xs: "85%", // 85% width on small devices
                        md: "90%", // 60% width on medium devices and larger
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <SyntaxHighlighter
                  language="javascript"
                  style={atomOneDark}
                  customStyle={{
                    borderRadius: "10px",
                    width: isSmallDevice ? "95%" : "50%",
                  }}>
                  {codeString}
                </SyntaxHighlighter>
              </Grid>
            </Grid>
          </div>
        ) : (
          <></>
        )}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            fontSize: {
              xs: "1.2rem", // Extra small devices
              sm: "1.7rem", // Small devices
              md: "1.7rem", // Medium devices
              lg: "1.7rem", // Large devices
            },
            marginTop: "3%",
          }}>
          Challenges
        </Typography>
        <Box
          component="div"
          sx={{ width: { xs: "90%", md: "75%", marginLeft: "5%" } }}>
          {challenges.map((challenge) => (
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                fontFamily: "inter",
                marginTop: "3%",
              }}>
              <span>{challenge["challenge"]}: </span>
              <span
                style={{
                  fontWeight: "normal",
                  fontFamily: "inter",
                  fontSize: "1.2rem",
                  color: "#646F77",
                }}>
                {challenge["description"]}
              </span>
            </Typography>
          ))}
        </Box>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            fontSize: {
              xs: "1.2rem", // Extra small devices
              sm: "1.7rem", // Small devices
              md: "1.7rem", // Medium devices
              lg: "1.7rem", // Large devices
            },
            marginTop: "3%",
          }}>
          Conclusion
        </Typography>
        <Box
          component="div"
          sx={{
            width: {
              xs: "90%",
              md: "75%",
              marginLeft: "5%",
              paddingBottom: "20%",
            },
          }}>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "inter",
              fontSize: "1.2rem",
              color: "#646F77",
            }}>
            {conclusion}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};
export default ProjectDetailed;
