import { Container, Typography } from "@mui/material";
import "./Gallery.css"; // Optional: You can style the gallery using this CSS file
const images = [
  "https://res.cloudinary.com/dmhnq0aww/image/upload/v1728068919/0_it3lad.jpg",
  "https://res.cloudinary.com/dmhnq0aww/image/upload/v1728068920/IMG_20211002_170727_mtpayj.jpg",
  "https://res.cloudinary.com/dmhnq0aww/image/upload/v1728068920/IMG_20210921_165636_2_dadkwk.jpg",
  "https://res.cloudinary.com/dmhnq0aww/image/upload/v1728068920/1_rarzwd.jpg",

  "https://res.cloudinary.com/dmhnq0aww/image/upload/v1728068919/1662975892884_mhglbl.jpg",
  "https://res.cloudinary.com/dmhnq0aww/image/upload/v1728068919/1662975892910_jsh2zz.jpg",
];

const PhotoGallery = () => {
  return (
    <div className="photo-gallery">
      {images.map((image, index) => (
        <div key={index} className="photo-gallery-item">
          <img src={image} alt={`Photography ${index + 1}`} />
        </div>
      ))}
    </div>
  );
};

const Gallery = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ padding: "3rem 0", backgroundColor: "transparent" }}>
      {/* About Title */}
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "#646F77",
          fontSize: {
            xs: "1.4rem", // Extra small devices
            sm: "2rem", // Small devices
            md: "2rem", // Medium devices
            lg: "2rem", // Large devices
          },
          marginTop: "2rem",
          marginLeft: {
            xs: "1rem",
            md: 0,
          },
        }}>
        {" "}
        Gallery
      </Typography>
      <Typography
        variant="body1"
        paragraph
        sx={{
          fontFamily: "Inter",
          fontSize: {
            xs: "1rem", // Extra small devices
            sm: "1rem", // Small devices
            md: "1rem", // Medium devices
            lg: "1rem", // Large devices
          },
          color: "#646F77",
          marginLeft: {
            xs: "1rem",
            md: 0,
          },
        }}>
        Here are some of the photos I've taken and renders done on Blender
      </Typography>
      <div
        style={{
          //   width: "90%" /* Or any percentage that fits the layout */,
          margin: "0 auto" /* This centers the container horizontally */,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <PhotoGallery />
      </div>
    </Container>
  );
};
export default Gallery;
