import React from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";

const AboutPage = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ padding: "2rem 0", backgroundColor: "transparent" }}>
      {/* About Title */}
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "#646F77",
          fontSize: {
            xs: "1.4rem", // Extra small devices
            sm: "2rem", // Small devices
            md: "2rem", // Medium devices
            lg: "2rem", // Large devices
          },
          marginBottom: "2rem",
          marginLeft: {
            xs: "2rem",
            md: 0,
          },
        }}>
        About
      </Typography>

      <Grid container spacing={4}>
        {/* Image */}
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Card
            sx={{
              boxShadow: 0,
              marginTop: { xs: "0", md: "6%" },
              marginLeft: { xs: 0, md: "-10%" },
              backgroundColor: "transparent",
            }}>
            <CardMedia
              component="div"
              height="150"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "transparent",
                borderRadius: "20px",
              }}>
              <img
                src="./Intersect.png"
                alt="profile"
                style={{
                  maxWidth: "35%",
                  maxHeight: "35%",
                  objectFit: "contain",
                  borderRadius: "10px",
                }}
                sx={{
                  maxWidth: {
                    xs: "10%",
                  },
                  maxHeight: {
                    xs: "10%",
                  },
                }}
              />
            </CardMedia>
          </Card>
        </Grid>

        {/* Details */}
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <Card sx={{ boxShadow: 0, backgroundColor: "transparent" }}>
            <CardContent>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontFamily: "Inter",
                  fontSize: {
                    xs: "1rem", // Extra small devices
                    sm: "1rem", // Small devices
                    md: "1rem", // Medium devices
                    lg: "1rem", // Large devices
                  },
                  color: "#646F77",
                }}>
                Hey there! I'm Rishi and it's great to see you here. I'm from
                Chennai, India. I'm a Full Stack Developer and I'm working at
                Cognizant India as an Associate Software Engineer. It never
                ceases to amaze me how some lines of code can do so much, and
                that's what drew me to this field. Currently, I'm focused on web
                development, and I'm also learning about game development and 3D
                modeling.
              </Typography>

              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontFamily: "Inter",
                  fontSize: {
                    xs: "1rem", // Extra small devices
                    sm: "1rem", // Small devices
                    md: "1rem", // Medium devices
                    lg: "1rem", // Large devices
                  },
                  color: "#646F77",
                }}>
                In my spare time, I absolutely love taking photos, especially
                macro shots of the world around us. I'm currently working on
                some personal projects and I'm really excited to dive into the
                world of AI for my next one. I'm also having a blast learning 3D
                modeling by playing around with Blender and Unreal Engine.
              </Typography>

              <Typography
                variant="body1"
                paragraph
                sx={{
                  marginTop: "30%",
                  fontFamily: "Inter",
                  fontSize: {
                    xs: "0.9rem", // Extra small devices
                    sm: "1rem", // Small devices
                    md: "1rem", // Medium devices
                    lg: "1rem", // Large devices
                  },
                  color: "#646F77",
                }}>
                Behold, an impressive sheet of paper awaits you, ready to reveal
                the depths of my professional journey. It can be found{" "}
                <a
                  href="/Resume/Resume.pdf"
                  style={{ color: "#A46FB5" }}
                  download>
                  here
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutPage;
