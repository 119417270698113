import { Box, Typography } from "@mui/material";
import { FaReact, FaGithub, FaLinkedin } from "react-icons/fa";
import { SlSocialInstagram } from "react-icons/sl";
import { AiFillHeart } from "react-icons/ai";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        padding: "1.5rem 0",
        textAlign: "center",
        marginTop: "3rem",
      }}>
      <Typography
        variant="body1"
        sx={{ color: "#646F77", fontSize: "1rem", fontWeight: "500" }}>
        Made with{" "}
        <AiFillHeart
          style={{
            color: "red",
            verticalAlign: "middle",
            margin: "0 0.3rem",
          }}
        />{" "}
        and{" "}
        <FaReact
          style={{
            color: "#09B2E1",
            verticalAlign: "middle",
            margin: "0 0.3rem",
          }}
        />{" "}
        by Rishi Rajan
      </Typography>

      <Box
        sx={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "center",
          gap: "1.5rem",
        }}>
        <a
          href="https://github.com/RishiRajan"
          target="_blank"
          rel="noopener noreferrer">
          <FaGithub size={24} color="#646F77" />
        </a>
        <a
          href="https://www.linkedin.com/in/r-rishi/"
          target="_blank"
          rel="noopener noreferrer">
          <FaLinkedin size={24} color="#646F77" />
        </a>
        <a
          href="https://www.instagram.com/therishirajan/"
          target="_blank"
          rel="noopener noreferrer">
          <SlSocialInstagram size={24} color="#646F77" />
        </a>
      </Box>
    </Box>
  );
};

export default Footer;
