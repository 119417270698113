import * as React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { Link } from "react-router-dom";

const drawerWidth = 240;
const navItems = ["About", "Gallery"];

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const NavBar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center everything inside the Box
        textAlign: "center",
        width: "100%", // Ensure Box takes full width
      }}>
      <Link
        to={"/"}
        style={{
          textDecoration: "none", // Remove underline
          fontFamily: "Inter", // Set font to Inter
          width: "100%", // Ensure link spans full width
          color: "#101010",
        }}>
        <Typography variant="body1" sx={{ mt: 1 }}>
          Home
        </Typography>
      </Link>

      {/* Ensure the List spans the full width */}
      <List
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        {navItems.map((item) => (
          <ListItem
            key={item}
            disablePadding
            sx={{ width: "100%", justifyContent: "center" }}>
            {" "}
            {/* Center each list item */}
            <Link
              to={"/" + item}
              style={{
                textDecoration: "none", // Remove underline
                fontFamily: "Inter", // Set font to Inter
                width: "100%", // Ensure link spans full width
                color: "#101010",
              }}>
              <ListItemButton
                sx={{
                  justifyContent: "center", // Center content inside button
                  textAlign: "center",
                  width: "100%", // Make button span full width
                }}>
                <ListItemText
                  primary={item}
                  sx={{ textAlign: "center", width: "100%" }} // Ensure text is centered
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar
          component="nav"
          sx={{
            background: "white",
            boxShadow: "none",
            paddingTop: "2%",
            transition: "background-color 0.3s ease-in-out",
          }}>
          <Toolbar disablePadding>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}>
              <MenuIcon sx={{ color: "#101010" }} />
            </IconButton>
            <Typography
              variant="h5"
              component={Link}
              to="/"
              sx={{
                display: { xs: "none", sm: "block" },
                color: "#101010",
                fontFamily: "Inter",
                fontWeight: "bold",
                fontSize: "1.89rem",
                letterSpacing: "0.15em",
                marginLeft: "5%",
                width: "60%",
                textDecoration: "none",
              }}>
              Rishi
            </Typography>
            <Box
              sx={{
                display: { xs: "none", sm: "block" },
                marginRight: "5%",
                flexGrow: "1",
              }}>
              {navItems.map((item) => (
                <Link to={"/" + item}>
                  <Button
                    key={item}
                    disableRipple
                    className="NavButtons"
                    sx={{
                      color: "#101010",
                      fontFamily: "Inter",
                      marginRight: "15%",
                    }}>
                    {item}
                  </Button>
                </Link>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
            fontFamily: "Inter",
          }}>
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

NavBar.propTypes = {
  window: PropTypes.func,
};

export default NavBar;
