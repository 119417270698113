import React from "react";
import { Handle, Position } from "@xyflow/react";
import "@xyflow/react/dist/style.css";

const CustomNode = ({ data }) => {
  return (
    <div
      style={{
        width: 75,
        height: 75,
        borderRadius: "100%",
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative", // Ensure relative positioning for handles
        border: "2px solid black",
        zIndex: 1,
      }}>
      {data.label}

      {/* Custom Handle */}
      <Handle
        type="source"
        position={Position.Top}
        style={{
          background: "transparent",
          top: 50, // Custom top position for handle
          //   display: "none",
          border: 0,
          zIndex: 0,
        }}
      />
      <Handle
        type="target"
        position={Position.Bottom}
        style={{
          bottom: 50,
          background: "transparent",
          border: 0,
          //   display: "none",
          zIndex: 0,
        }}
      />
    </div>
  );
};

export default CustomNode;
