import { getData } from "../../Data/Data";
import ProjectCard from "../../Components/Utils/ProjectCard/ProjectCard";
import { Grid } from "@mui/material";

const ProjectLister = () => {
  const projectData = getData().projects;

  return (
    <>
      <Grid
        container
        justifyContent="center"
        spacing={{ xs: 0, sm: 0, md: 2, lg: 0 }}>
        {projectData.map((project) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={project.name}
            display="flex"
            justifyContent="center"
            sx={{
              padding: 0,
              margin: "0 4%", // Ensure it stays centered
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <ProjectCard project={project} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default ProjectLister;
