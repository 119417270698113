import Flippable from "../Utils/Greeting/Flippable";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import "./HeroSection.css";

const HeroSection = () => {
  const handleConnectClick = () => {
    window.location.href = "mailto:mailrishirajan@gmail.com?subject=Hello!";
  };
  return (
    <div style={{ marginTop: "15%" }}>
      <Flippable text={["Hello", "வணக்கம்", "नमस्ते"]} />
      <div className="namepart" style={{ marginLeft: "10%" }}>
        <Typography
          variant="h2"
          component="div"
          sx={{
            fontSize: {
              xs: "1.8rem", // Extra small devices
              sm: "2rem", // Small devices
              md: "2.25rem", // Medium devices
              lg: "2.25rem", // Large devices
            },
          }}>
          I'm{" "}
          <Typography
            variant="h2"
            component="span"
            sx={{
              fontFamily: "Inter",
              letterSpacing: "0.15em",
              fontWeight: "bold",
              fontSize: {
                xs: "1.8rem", // Extra small devices
                sm: "2rem", // Small devices
                md: "2.25rem", // Medium devices
                lg: "2.25rem", // Large devices
              },
            }}>
            Rishi
          </Typography>
        </Typography>
      </div>
      <div
        className="Description"
        style={{ marginLeft: "10%", maxWidth: "80%" }}>
        <Typography
          variant="h4"
          component={"div"}
          sx={{
            fontSize: {
              xs: "1rem", // Extra small devices
              sm: "1.5rem", // Small devices
              md: "1.5rem", // Medium devices
              lg: "1.5rem", // Large devices
            },
          }}>
          Full stack developer building things for the Web
        </Typography>
        <Button
          variant="contained"
          className="connectButton"
          disableRipple
          onClick={handleConnectClick}
          sx={{
            backgroundColor: "#3C4048",
            // marginTop: "10%",
            marginTop: {
              xs: "10%", // Small devices
              lg: "3%", // Large devices
            },
            textTransform: "none",
            fontSize: {
              xs: "0.75rem", // Extra small devices
              sm: "1rem", // Small devices
              md: "1.1rem", // Medium devices
              lg: "1.2rem", // Large devices
            },
          }}>
          Let's Connect
        </Button>
      </div>
    </div>
  );
};

export default HeroSection;
