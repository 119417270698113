const Projects = {
  projects: [
    {
      id: 1,
      name: "FlowWeave",
      description:
        "A Flow based Visual coding App for JavaScript. Inspired from Unreal Engines’s blueprints",
      status: "Coming soon",
      tags: ["Personal Project"],
      image: "/Assets/FlowWeave/titleImage.gif",
    },
    {
      id: 2,
      name: " DataLens",
      description: `A Real-Time Data Dashboard that allows users to input queries and generates tailored data visualizations and reports. `,
      status: "Coming soon",
      tags: ["Personal Project"],
      image:
        "https://www.zohowebstatic.com/sites/zweb/images/creator/app-deck/pos-ss3.png",
    },
  ],
};

export function getData() {
  return Projects;
}
